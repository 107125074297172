<template>
  <div>
    <div id="card-element">
      <!-- Stripe.js injects the card input here -->
    </div>
    <div class="col-12 col-md mt-3 mt-md-0">
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          id="payment-consent"
          required
        />
        <label class="custom-control-label mt-4" for="payment-consent">
          <span>
            By checking this box, I authorize {{ companyName }} to process my
            payment and consent to the collection, use, and disclosure of my
            personal information in accordance with the
          </span>
          <a target="_blank" href="/privacy-policy">Privacy Policy </a>
          <span>and </span>
          <a target="_blank" href="/terms">Terms &amp; Conditions</a>.
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { loadStripe } from "@stripe/stripe-js";

export default {
  data() {
    return {
      cardElement: undefined,
    };
  },
  mounted() {
    this.initStripe();
  },
  computed: {
    ...mapState({
      companyName: (state) => (state.settings.companyProfile || {}).name || "",
    }),
  },
  methods: {
    ...mapActions({
      getPublicSettings: "settings/getPublicSettings",
    }),
    initStripe: async function () {
      try {
        const settings = await this.getPublicSettings();
        if (settings["STRIPE_PUBLIC"] && !window.stripe) {
          const stripe = await loadStripe(settings["STRIPE_PUBLIC"]);
          window.stripe = stripe;
          const cardElement = stripe.elements()?.create("card", {
            style: {
              base: {
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "2.2",
              },
            },
          });
          window.cardElement = cardElement;
          cardElement.mount("#card-element");
        } else if (settings["STRIPE_PUBLIC"] && window.stripe) {
          const cardElement = window.stripe.elements()?.create("card", {
            style: {
              base: {
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "2.2",
              },
            },
          });
          window.cardElement = cardElement;
          cardElement.mount("#card-element");
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style>
#card-element {
  background-color: white;
  padding-left: 15px;
}
</style>
