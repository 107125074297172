<template>
  <button
    :class="className"
    @click="copyBookingPortalURLHandler"
    title="Copy Booking Portal URL"
  >
    <i class="fas fa-copy"></i>
    Copy Booking Portal URL
  </button>
</template>
<script>
import Swal from "sweetalert2";

export default {
  props: {
    className: {
      type: String,
      default: "btn btn-black",
    },
  },
  data() {
    return {
      bookingPortalRoute: "/booking",
    };
  },
  methods: {
    copyBookingPortalURLHandler() {
      const url = `${window.location.origin}${this.bookingPortalRoute}`;
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      });
      navigator.clipboard.writeText(url).then(
        () => {
          Toast.fire({
            icon: "success",
            title: "Booking Portal URL copied to clipboard",
          });
        },
        () => {
          Toast.fire({
            icon: "error",
            title: "Failed to copy Public Booking Page URL",
          });
        }
      );
    },
  },
};
</script>
<style lang=""></style>
