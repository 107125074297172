<template>
  <modal
    name="booking-verification-modal"
    transition="pop-out"
    class="booking-verification-modal"
    :width="400"
    :focus-trap="true"
    :height="500"
  >
    <div class="boxVerification">
      <div class="" id="bp-left">
        <div class="partition" id="partition-verification">
          <div class="partition-title">Booking Verification</div>

          <div class="col-md-12" style="text-align: center">
            <img
              :src="companyLogo || companyLogoPlaceholder"
              class="site-rounded-logo rounded"
            />
          </div>
          <div class="partition-form">
            <form autocomplete="false">
              <div class="col-md-11 form-container">
                <div class="col-md-12 text-center" style="text-align: left">
                  <h3>Enter the token we just sent you via email</h3>
                </div>
                <div class="col-md-12 d-flex justify-content-center">
                  <otp-input
                    :digits="6"
                    type="text"
                    placeholder=""
                    :isDisabled="loading"
                    :isLoading="loading"
                    @on-complete="verifyToken"
                  />
                </div>
                <a href="#" @click.prevent="!loading && closeModal()">
                  Start Over
                </a>
                <div class="mt-4">
                  Need help? Contact
                  <a href="mailto:info@communitycareconnection.com">
                    Our Support
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapState } from "vuex";
import OtpInput from "@/components/OtpInput.vue";

export default {
  name: "BookingVerificationModal",
  components: { OtpInput },
  emits: ["verifyToken"],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      companyLogoPlaceholder: require("@/assets/img/c3-logo.png"),
    };
  },
  computed: {
    ...mapState({
      companyLogo: (state) => state.settings.companyLogo,
    }),
  },
  methods: {
    verifyToken: function (token) {
      this.$emit("verifyToken", token);
    },
    closeModal: function () {
      this.$modal.hide("booking-verification-modal");
    },
  },
};
</script>
<style lang="scss">
.booking-verification-modal {
  .v--modal {
    border-radius: 36px;
  }
}
.partition-form {
  margin-top: 20px;
}
.boxVerification {
  background: white;
  width: 400px;
  height: 500px;
  border-radius: 36px;
  box-sizing: border-box;
  box-shadow: 0 0 40px black;
  color: #254b7b;
  font-size: 12px;
  a {
    color: #03a9f4;
    font-size: 15px;
  }
  .form-container {
    text-align: center;
    margin: 0 auto;
  }

  input[type="checkbox"] {
    transform: scale(1.2);
  }
  .partition {
    width: 100%;
    height: 100%;
    .partition-title {
      box-sizing: border-box;
      padding: 30px;
      padding-top: 10px;
      padding-bottom: 0px;
      width: 100%;
      text-align: center;
      letter-spacing: 1px;
      font-size: 30px;
      font-weight: 300;
    }
  }
  .large-btn {
    background: white;
    span {
      font-weight: 600;
    }
    &:hover {
      color: white !important;
    }
  }
}
.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}
.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}
.checkboxLabel {
  font-size: 17px;
  line-height: 17px;
  position: relative;
  bottom: 1px;
}

@media (max-width: 767px) {
  .booking-verification-modal {
    .v--modal {
      width: 90% !important;
      left: 5% !important;

      .boxVerification {
        width: 100%;
      }
    }
  }
}
</style>
