<template>
  <modal
    name="rejectBookingRequestModal"
    transition="pop-out"
    class="reject-booking-request-modal"
    :width="600"
    :focus-trap="true"
    :clickToClose="false"
    height="auto"
  >
    <div class="position-relative modal-outer-container">
      <div class="modal-header border-bottom">
        <div class="ml-3 pl-2">
          Please provide a reason for rejecting the booking request
        </div>
        <button class="btn border-0" @click="closeModal">
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
      <form @submit.prevent="rejectBookingRequestHelper">
        <div class="col-12 modal-body">
          <div class="form-group">
            <label>Reason</label>
            <textarea
              class="form-control"
              v-model.trim="rejectionReason"
              rows="5"
              required
            ></textarea>
            <small class="text-muted">
              This will be included in the rejection email sent to the user
            </small>
          </div>
        </div>
        <div
          class="
            d-flex
            justify-content-center
            border-top
            align-items-center
            py-3
          "
        >
          <button class="btn btn-danger mr-3" @click.prevent="cancel">
            Cancel
          </button>
          <save
            classes="btn btn-black"
            :saving="rejectBookingRequestLoading == bookingRequest.id"
            type="submit"
          >
            Reject Request
          </save>
        </div>
      </form>
    </div>
  </modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  props: {
    bookingRequest: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rejectionReason: "",
    };
  },
  computed: {
    ...mapState({
      rejectBookingRequestLoading: (state) =>
        state.booking.rejectBookingRequestLoading,
    }),
  },
  methods: {
    ...mapActions({
      rejectBookingRequest: "booking/rejectBookingRequest",
    }),
    rejectBookingRequestHelper: async function () {
      const payload = {
        bookingRequestId: this.bookingRequest.id,
        rejection_reason: this.rejectionReason,
      };
      const success = await this.rejectBookingRequest(payload);
      if (success) {
        Swal.fire({
          title: "Success",
          text: "Booking request has been rejected successfully",
          icon: "success",
        });
        this.$emit("finish");
        this.resetFormData();
        this.closeModal();
      }
    },
    resetFormData: function () {
      this.rejectionReason = "";
    },
    cancel: function () {
      this.resetFormData();
      this.closeModal();
    },
    closeModal: function () {
      this.$modal.hide("rejectBookingRequestModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.reject-booking-request-modal {
  .modal-body {
    max-height: 300px;
    overflow-y: auto;
  }
}
</style>
