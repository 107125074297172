<template>
  <div>
    <modal
      name="consentAttachmentsModal"
      transition="pop-out"
      class="consent-attachments-modal"
      :width="800"
      :focus-trap="true"
      :clickToClose="false"
      height="auto"
    >
      <div class="position-relative modal-outer-container">
        <div class="modal-header border-bottom">
          <div class="ml-3 pl-2">Attachments</div>
          <button class="btn border-0" @click="closeModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
      </div>
      <div class="p-2">
        <div class="scrollable-content">
          <alert v-if="attachments.length == 0" class="m-4">
            No Attachments to Show
          </alert>
          <table v-else class="table table-striped border-top-0">
            <tr>
              <th>Icon</th>
              <th><span class="desktop-only">Name</span></th>
              <th class="desktop-only">Size</th>
              <th class="desktop-only"></th>
            </tr>
            <tr v-for="attachment in attachments" :key="attachment.id">
              <td>
                <icon
                  :type="
                    getFileTypeByExtension(attachment)
                      ? `file-${getFileTypeByExtension(attachment)}`
                      : 'file'
                  "
                  class="fa-2x text-muted m-0"
                />
              </td>
              <td>
                {{ attachment.original_file_name }}
                <br />
                <span v-if="attachment.created_at">
                  <span class="text-right text-muted small">
                    {{ prettyDate(attachment.created_at) }}
                  </span>
                  <br />
                </span>
                <b class="mobile-tablet-only mr-2">Size:</b>
                <span class="small text-muted mobile-tablet-only">
                  {{ attachment.readable_size }}
                </span>
                <div class="mobile-tablet-only">
                  <div class="mb-3 mt-2">
                    <button
                      v-if="canViewFile(attachment)"
                      @click="viewFile(attachment)"
                      class="btn btn-sm btn-theme mt-2"
                    >
                      View
                    </button>
                    <button
                      @click="downloadFile(attachment)"
                      class="btn btn-sm btn-black mt-2 ml-2"
                    >
                      Download
                    </button>
                  </div>
                </div>
              </td>
              <td class="desktop-only">
                <span class="small text-muted">{{
                  attachment.readable_size
                }}</span>
              </td>
              <td class="text-right desktop-only">
                <button
                  v-if="canViewFile(attachment)"
                  @click="viewFile(attachment)"
                  class="btn btn-sm btn-theme mt-2"
                >
                  View
                </button>
                <button
                  @click="downloadFile(attachment)"
                  class="btn btn-sm btn-black mt-2 ml-2"
                >
                  Download
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </modal>
    <file-viewer
      :name="selectedAttachment.name"
      :type="selectedAttachment.type"
      :link="selectedAttachment.link"
    />
  </div>
</template>
<script>
import helpers from "@/utils/helpers";
export default {
  props: {
    consent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedAttachment: {
        name: "",
        type: "",
        link: "",
      },
    };
  },
  computed: {
    attachments() {
      return this.consent?.attachments || [];
    },
  },
  methods: {
    getFileTypeByExtension: (attachment) =>
      helpers.getFileTypeByExtension(attachment.extension),
    canViewFile: function (attachment) {
      const allowedExtensions = [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "ppt",
        "pptx",
        "jpg",
        "jpeg",
        "png",
      ];
      return allowedExtensions.includes(attachment.extension);
    },
    viewFile: function (attachment) {
      this.selectedAttachment = {
        name: attachment.original_file_name,
        type: this.getFileTypeByExtension(attachment),
        link: attachment.path,
      };
      setTimeout(() => {
        this.$modal.show("file-viewer-modal");
      });
    },
    downloadFile: function (attachment) {
      window.open(attachment.path, "_blank");
    },
    prettyDate: function (date) {
      return helpers.prettyDate(date);
    },
    closeModal() {
      this.$modal.hide("consentAttachmentsModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
}
</style>
