<template>
  <div class="noPrint">
    <div
      class="conversation-list"
      :class="{
        closed: !isExpanded && !isFullscreen,
        expand: isExpanded,
        fullscreen: isFullscreen,
      }"
    >
      <header>
        <div class="overlay" @click="toggleExpand"></div>
        <div class="title pointer-cursor text-white" @click="toggleExpand">
          <span class="messaging-span">Messaging</span>
          <span v-if="unreadCount > 0" class="badge badge-danger ml-2">
            {{ unreadCount }}
          </span>
        </div>
        <div class="icons text-white">
          <div>
            <!-- icon to expand -->
            <i
              class="fa fa-chevron-down"
              aria-hidden="true"
              @click="toggleExpand"
              v-if="isExpanded"
            ></i>
            <!-- icon to minimize -->
            <i
              class="fa fa-chevron-up"
              aria-hidden="true"
              @click="toggleExpand"
              v-else
            ></i>
          </div>
          <!-- icon to full screen in dev next to previous icon  -->
          <div>
            <i
              class="fa fa-compress"
              aria-hidden="true"
              @click="toggleFullscreen"
              v-if="isFullscreen"
            ></i>
            <!-- icon to minimize -->
            <i
              class="fa fa-expand"
              aria-hidden="true"
              @click="toggleFullscreen"
              v-else
            ></i>
          </div>
        </div>
      </header>
      <section>
        <vue-advanced-chat
          ref="vac"
          :current-user-id="String(loggedInUser.id)"
          height="80vh"
          :rooms="JSON.stringify(rooms)"
          :loading-rooms="initiateRoomsLoading"
          :rooms-loaded="roomsLoaded"
          :messages="JSON.stringify(msgs)"
          :text-messages="
            JSON.stringify({
              ROOMS_EMPTY: 'No Conversations Found',
              SEARCH: 'Search for a conversation',
            })
          "
          :messages-loaded="messagesLoaded"
          :menu-actions="
            JSON.stringify(shouldSeeMenuActions ? menuActions : [])
          "
          load-first-room="false"
          custom-search-room-enabled="true"
          :show-add-room="canCreateConversation"
          show-search="true"
          show-audio="false"
          user-tags-enabled="false"
          show-reaction-emojis="false"
          :responsive-breakpoint="3000"
          room-info-enabled="true"
          show-files="false"
          @room-info="$modal.show('updateConversationModal')"
          @room-action-handler="menuActionHandler($event.detail[0])"
          @menu-action-handler="menuActionHandler($event.detail[0])"
          @fetch-more-rooms="getConversationsHelper(conversations.page + 1)"
          @fetch-messages="getMessagesHelper($event.detail[0])"
          @send-message="sendMessageHelper($event.detail[0])"
          @search-room="applySearch"
          :username-options="
            JSON.stringify({ minUsers: 0, currentUser: false })
          "
          @toggle-rooms-list="handleToggleRoomList($event.detail[0])"
          @add-room="$modal.show('createConversationModal')"
          :styles="JSON.stringify(customChatStyle)"
        >
          <div
            v-for="room in rooms"
            :key="room.roomId"
            :slot="'room-list-info_' + room.roomId"
          >
            <div v-if="room.isMuted" class="mt-1 mx-1">
              <i class="fas fa-volume-mute fa-lg" style="color: #546e7a"></i>
            </div>
          </div>
        </vue-advanced-chat>
      </section>
    </div>
    <create-conversation-modal @conversation-created="getConversationsHelper" />
    <update-conversation-modal
      :conversation="getSelectedConversation"
      @conversation-updated="handleConversationUpdate"
      @conversation-avatar-updated="handleConversationAvatarUpdate"
      v-if="selectedConversation"
    />
    <conversation-users-modal
      :conversation="roomActionsConversation"
      :modalType="conversationUsersModalType"
      @conversation-participant-added="handleConversationParticipantAdded"
      @conversation-participant-removed="handleConversationParticipantRemoved"
      v-if="roomActionsConversation"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { register } from "@ray-solutions/vue-advanced-chat";
import CreateConversationModal from "./CreateConversationModal.vue";
import UpdateConversationModal from "./UpdateConversationModal.vue";
import ConversationUsersModal from "./ConversationUsersModal.vue";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import { ConversationParticipantType } from "../../utils/Enum.js";
register();
export default {
  name: "ConversationList",
  components: {
    CreateConversationModal,
    UpdateConversationModal,
    ConversationUsersModal,
  },
  data() {
    return {
      initiateRoomsLoading: false,
      roomActionsConversation: null,
      conversationUsersModalType: null,
      limit: 30,
      memberRoomActions: [
        { name: "muteUnmuteConversation", title: "Mute/Unmute Conversation" },
        { name: "leaveConversation", title: "Leave Conversation" },
      ],
      adminRoomActions: [
        { name: "inviteUser", title: "Invite User" },
        { name: "removeUser", title: "Remove User" },
        { name: "muteUnmuteConversation", title: "Mute/Unmute Conversation" },
        { name: "leaveConversation", title: "Leave Conversation" },
      ],
      menuActions: [
        { name: "inviteUser", title: "Invite User" },
        { name: "removeUser", title: "Remove User" },
      ],
      filters: {
        conversationName: "",
      },
      customChatStyle: {
        general: {
          colorSpinner: "#fff",
        },
        content: {
          background: "#222222",
        },
        message: {
          colorStarted: "#fff",
          colorUsername: "#195ea2",
        },
      },
    };
  },
  mounted() {
    this.getUnreadMessagesCount();
    // TODO: Enable this when the caching issue is resolved
    // this.getOnlineUsers();
    // window.Echo.encryptedPrivate("online-users").listen(
    //   "OnlineUsersStatus",
    //   (e) => {
    //     if (e?.onlineUsers?.environment_name == this.companyName) {
    //       this.setOnlineUsers(e.onlineUsers.users);
    //     }
    //   }
    // );
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
    this.getConversationsHelper();
    window.Echo.encryptedPrivate("conversations").listen(
      "ConversationCreatedOrUpdated",
      (e) => {
        if (e.environment_name != this.companyName) return;
        const participantsIds = e.conversation.participants.map(
          (participant) => participant.user.id
        );
        const conversationExist = this.getRawConversation(e.conversation.id);

        if (participantsIds.includes(this.loggedInUser.id)) {
          if (conversationExist) {
            delete e.conversation.participant;
            delete e.conversation.is_muted;
            this.updateOrCreateConversation(e.conversation);
            this.$nextTick(() => {
              this.displayNotification(e.conversation);
            });
          } else {
            this.getConversation({ conversationId: e.conversation.id }).then(
              (res) => {
                if (res) {
                  this.$nextTick(() => {
                    this.displayNotification(res.data.data);
                  });
                }
              }
            );
          }
        } else {
          this.removeConversation({
            conversationId: e.conversation.id,
          });
        }
      }
    );
  },
  destroyed() {
    window.Echo.leaveChannel("online-users");
    window.Echo.leaveChannel(`conversations`);
  },
  watch: {
    conversations: {
      handler() {
        if (this.roomActionsConversation) {
          this.roomActionsConversation = this.getRawConversation(
            this.roomActionsConversation.id
          );
        }
      },
      deep: true,
    },
    onlineUsers: {
      handler() {
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      conversations: (state) => state.chat.conversations.pagination,
      conversationsLoading: (state) => state.chat.conversations.loading,
      conversationMessagesLoading: (state) =>
        state.chat.conversationMessages.loading,
      conversationMessages: (state) =>
        state.chat.conversationMessages.pagination,
      loggedInUser: (state) => state.auth.user,
      selectedConversation: (state) => state.chat.selectedConversation,
      unreadCount: (state) => state.chat.unreadCount,
      onlineUsers: (state) => state.auth.onlineUsers,
      isExpanded: (state) => state.chat.isExpanded,
      isFullscreen: (state) => state.chat.isFullscreen,
      companyName: (state) => (state.settings.companyProfile || {}).name || "",
    }),
    DefaultAvatar: function () {
      return require("@/assets/img/avatars/profiles/avatar.jpg");
    },
    rooms: function () {
      return this.conversations.data.map((conversation) => {
        return {
          roomId: conversation.id,
          roomName: conversation.title,
          avatar: conversation.avatar || this.DefaultAvatar,
          unreadCount: conversation.unread_count || 0,
          index:
            conversation?.latest_message?.created_at || conversation.created_at,
          isMuted: conversation.is_muted,
          participant: conversation.participant,
          lastMessage: conversation.latest_message
            ? {
                _id: conversation.latest_message.id,
                content: conversation.latest_message.content || "ملف",
                system: conversation.latest_message.is_system_message,
                senderId: String(conversation.latest_message.sender.id),
                username: conversation.latest_message.is_system_message
                  ? null
                  : conversation.latest_message.sender.name,
                timestamp: conversation.latest_message.created_at_relative,
                distributed: true,
                seen:
                  conversation.latest_message.sender.id ===
                    this.loggedInUser.id || conversation.latest_message.read_at,
                new:
                  conversation.latest_message.sender.id !==
                    this.loggedInUser.id &&
                  !conversation.latest_message.read_at,
              }
            : null,
          users: conversation.participants.map((participant) => {
            return {
              _id: String(participant.user.id),
              username: participant.name,
              avatar: participant.user.avatar || this.DefaultAvatar,
              status: {
                state: this.isOnline(participant.user.id)
                  ? "online"
                  : "offline",
              },
            };
          }),
          roomActions:
            conversation.participant.role == ConversationParticipantType.ADMIN
              ? this.adminRoomActions
              : this.memberRoomActions,
        };
      });
    },
    msgs: function () {
      return this.conversationMessages.data.map((message) => {
        return {
          _id: message.id,
          indexId: message.id,
          content: message.content ?? "",
          system: message.is_system_message ?? false,
          senderId: String(message.sender.id),
          username: message.is_system_message ? null : message.sender.name,
          avatar: message.sender.avatar || this.DefaultAvatar,
          timestamp: dayjs(message.created_at).format("h:mm A"),
          date: dayjs(message.created_at).format("DD/MM/YYYY"),
          distributed: true,
          seen: message.sender.id === this.loggedInUser.id || message.read_at,
          new: message.sender.id !== this.loggedInUser.id && !message.read_at,
          deleted: false,
          failure: false,
          disableActions: true,
          disableReactions: true,
        };
      });
    },
    messagesLoaded: function () {
      return (
        !this.conversationMessagesLoading &&
        !this.conversationMessages.nextCursor
      );
    },
    roomsLoaded: function () {
      return this.conversations.page == this.conversations.lastPage;
    },
    getSelectedConversation: function () {
      return this.selectedConversation
        ? this.getRawConversation(this.selectedConversation.roomId)
        : null;
    },
    newMessageIcon: function () {
      return require("@/assets/img/new-message.gif");
    },
    canCreateConversation: function () {
      return (
        this.loggedInUser.isAdmin ||
        this.loggedInUser.isManagingAdmin ||
        this.loggedInUser.isProvider
      );
    },
    shouldSeeMenuActions: function () {
      return (
        this.selectedConversation &&
        this.selectedConversation.participant.role ==
          ConversationParticipantType.ADMIN
      );
    },
  },
  methods: {
    ...mapActions({
      getConversations: "chat/getConversations",
      getConversation: "chat/getConversation",
      getConversationMessages: "chat/getConversationMessages",
      sendConversationMessage: "chat/sendConversationMessage",
      markMessageAsRead: "chat/markMessageAsRead",
      setSelectedConversation: "chat/setSelectedConversation",
      resetConversationMessages: "chat/resetConversationMessages",
      storeConversationMessage: "chat/storeConversationMessage",
      setLatestConversationMessage: "chat/setLatestConversationMessage",
      updateOrCreateConversation: "chat/updateOrCreateConversation",
      removeConversation: "chat/removeConversation",
      removeConversationParticipant: "chat/removeConversationParticipant",
      toggleConversationMuteStatus: "chat/toggleConversationMuteStatus",
      getUnreadMessagesCount: "chat/getUnreadMessagesCount",
      setOnlineUsers: "auth/setOnlineUsers",
      getOnlineUsers: "auth/getOnlineUsers",
      setConversationListExpanded: "chat/setConversationListExpanded",
      setConversationListFullscreen: "chat/setConversationListFullscreen",
      markConversationAsRead: "chat/markConversationAsRead",
    }),
    toggleExpand: function () {
      if (!this.isExpanded && this.selectedConversation) {
        this.markConversationAsRead({
          conversationId: this.selectedConversation.roomId,
        });
      }
      this.setConversationListExpanded(!this.isExpanded);
      if (!this.isExpanded) this.setConversationListFullscreen(false);
    },
    toggleFullscreen: function () {
      this.setConversationListFullscreen(!this.isFullscreen);
      if (this.isFullscreen) this.setConversationListExpanded(true);
    },
    getConversationsHelper: function (page = 1) {
      if (page == 1) {
        this.initiateRoomsLoading = true;
      }
      this.getConversations({ page, limit: this.limit, ...this.filters }).then(
        (res) => {
          if (res) {
            this.initiateRoomsLoading = false;
          }
        }
      );
    },
    getMessagesHelper: function ({ options, room }) {
      if (options?.reset) {
        this.setSelectedConversation(room);
      }
      this.getConversationMessages({
        conversationId: room.roomId,
        cursor: options?.reset ? null : this.conversationMessages.nextCursor,
        limit: this.limit,
      }).then((res) => {
        if (res) {
          window.Echo.encryptedPrivate(
            `conversations.${room.roomId}.messages`
          ).listen("ConversationMessageSent", (e) => {
            if (e.environment_name != this.companyName) return;
            if (
              this.selectedConversation &&
              this.selectedConversation.roomId == e.message.conversation_id
            ) {
              this.storeConversationMessage(e.message);
              this.markMessageAsRead({
                conversationId: e.message.conversation_id,
                messageId: e.message.id,
              });
            }
          });
        }
      });
    },
    sendMessageHelper: function (details) {
      const messageContent = details.content;
      const conversationId = details.roomId;
      const isSystemMessage = details.isSystemMessage;
      const formattedMessage = this.formatMessage(
        messageContent,
        conversationId
      );
      this.storeConversationMessage(formattedMessage);
      this.setLatestConversationMessage(formattedMessage);
      this.sendConversationMessage({
        conversationId: conversationId,
        data: {
          content: messageContent,
          is_system_message: isSystemMessage,
        },
      });
    },
    menuActionHandler({ action, roomId }) {
      switch (action.name) {
        case "inviteUser":
          return this.inviteUser(roomId);
        case "removeUser":
          return this.removeUser(roomId);
        case "muteUnmuteConversation":
          return this.muteUnmuteConversation(roomId);
        case "leaveConversation":
          return this.leaveConversation(roomId);
      }
    },
    inviteUser: function (roomId) {
      const conversation = this.getRawConversation(roomId);
      this.roomActionsConversation = conversation;
      this.conversationUsersModalType = "add";
      this.$nextTick(() => {
        this.$modal.show("conversationUsersModal");
      });
    },
    removeUser: function (roomId) {
      const conversation = this.getRawConversation(roomId);
      this.roomActionsConversation = conversation;
      this.conversationUsersModalType = "remove";
      this.$nextTick(() => {
        this.$modal.show("conversationUsersModal");
      });
    },
    leaveConversation: function (roomId) {
      const conversation = this.getRawConversation(roomId);
      /**
       * Check if the participant who wants to leave the conversation is admin
       * and if he is the only admin in the conversation then display a warning
       * message
       */
      const countOfAdmins = conversation.participants.filter(
        (participant) => participant.role == ConversationParticipantType.ADMIN
      ).length;
      if (
        conversation.participant.role == ConversationParticipantType.ADMIN &&
        countOfAdmins == 1
      ) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-danger ml-4",
            cancelButton: "btn btn-secondary",
          },
          buttonsStyling: false,
        });

        swalWithBootstrapButtons
          .fire({
            title: "Warning",
            text: "You are the only admin in this conversation, if you leave it will be deleted",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Leave",
            cancelButtonText: "Cancel",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.removeConversation({
                conversationId: conversation.id,
              });
              this.removeConversationParticipant({
                conversationId: conversation.id,
                participantId: conversation.participant.id,
                leave: true,
              });
            }
          });
      } else {
        this.removeConversation({
          conversationId: conversation.id,
        });
        this.removeConversationParticipant({
          conversationId: conversation.id,
          participantId: conversation.participant.id,
          leave: true,
        });
      }
    },
    muteUnmuteConversation: function (roomId) {
      const conversation = this.getRawConversation(roomId);
      this.toggleConversationMuteStatus({
        conversationId: roomId,
        participantId: conversation.participant.id,
      });
    },
    handleToggleRoomList: function () {
      window.Echo.leaveChannel(
        `private-encrypted-conversations.${this.selectedConversation.roomId}.messages`
      );
      this.resetConversationMessages();
      this.setSelectedConversation(null);
    },
    handleConversationUpdate: function (conversation) {
      const message = this.formatMessage(
        `${this.loggedInUser.name} updated the conversation`,
        conversation.id,
        true
      );
      this.storeConversationMessage(message);
      this.setLatestConversationMessage(message);
    },
    handleConversationAvatarUpdate: function (conversation) {
      const message = this.formatMessage(
        `${this.loggedInUser.name} updated the conversation avatar`,
        conversation.id,
        true
      );
      this.storeConversationMessage(message);
      this.setLatestConversationMessage(message);
    },
    handleConversationParticipantAdded: function (payload) {
      const message = this.formatMessage(
        `${this.loggedInUser.name} added ${payload.participant.name} to the conversation`,
        payload.conversation.id,
        true
      );
      this.storeConversationMessage(message);
      this.setLatestConversationMessage(message);
    },
    handleConversationParticipantRemoved: function (payload) {
      const message = this.formatMessage(
        `${this.loggedInUser.name} removed ${payload.participant.name} from the conversation`,
        payload.conversation.id,
        true
      );
      this.storeConversationMessage(message);
      this.setLatestConversationMessage(message);
    },
    getRawConversation: function (conversationId) {
      return this.conversations.data.find(
        (conversation) => conversation.id == conversationId
      );
    },
    formatMessage: function (content, conversationId, isSystemMessage = false) {
      return {
        id: this.conversationMessages.data.length + 1,
        conversation_id: conversationId,
        sender: {
          id: this.loggedInUser.id,
          name: this.loggedInUser.name,
          avatar: this.loggedInUser.profile_picture,
        },
        content,
        is_system_message: isSystemMessage,
        created_at: dayjs(),
        created_at_relative: dayjs().fromNow(),
        read_at: dayjs(),
      };
    },
    displayNotification: function (conversation) {
      const title = conversation.title;
      const content = conversation.latest_message.content;
      const isSystemMessage = conversation.latest_message.is_system_message;
      const sender = conversation.latest_message.sender.name;
      const body = isSystemMessage ? content : `${sender}: ${content}`;

      // display notification if permission granted and document is hidden (not in focus)
      const rawConversation = this.getRawConversation(conversation.id);
      if (
        Notification.permission === "granted" &&
        document.hidden &&
        !rawConversation.is_muted
      ) {
        const notification = new Notification("From " + title, { body });
        notification.onclick = function () {
          window.focus();
          notification.close();
        };
      }

      /**
       * Display a toast if document is in focus and conversation is not muted and conversation list is not expanded
       */
      if (!document.hidden && !rawConversation.is_muted && !this.isExpanded) {
        // construct html body that contains conversation title and message content
        // conversation title at the top and below it the message content
        const htmlBody = `
          <div class="d-flex flex-column mx-2 my-2" style="max-width:300px;min-width:250px">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <span class="font-weight-bold text-truncate">From ${title}</span>
            </div>
            <div class="d-flex align-items-center">
              ${
                isSystemMessage
                  ? ""
                  : `<div class="mr-1 text-nowrap font-weight-bold">${sender}: </div>`
              }
              <div class="text-truncate">${content}</div>
            </div>
          </div>`;
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "info",
          iconHtml: `<img src="${this.newMessageIcon}" class="rounded-circle" style="width: 45px;height:45px;">`,
          html: htmlBody,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
    applySearch: debounce(function ({ detail }) {
      const searchText = detail[0].value;
      this.filters.conversationName = searchText;
      if (
        String(searchText).trim().length > 0 ||
        String(searchText).length == 0
      ) {
        this.getConversationsHelper();
      }
    }, 500),
    isOnline: function (userId) {
      return (this.onlineUsers || []).includes(userId);
    },
  },
};
</script>
<style lang="scss" scoped>
.conversation-list {
  position: fixed;
  bottom: -1px;
  right: 20px;
  @media (max-width: 768px) {
    width: calc(100vw - 50px);
  }
  max-height: 50px;
  width: 400px;
  background-color: #fff;
  border: 1px solid #222222;
  border-radius: 6px 6px 0 0;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #222222;
    .title {
      font-size: 18px;
      font-weight: 600;
      z-index: 3;

      .messaging-span {
        font-size: 17px;
        letter-spacing: 0.5px;
      }
    }
    .icons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      i {
        font-size: 18px;
        cursor: pointer;
      }
      z-index: 3;
    }
    &:hover {
      .overlay {
        background-color: #111111;
      }
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 47px;
    background-color: #222222;
    border-radius: 4px 4px 0 0;
    z-index: 1;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
  }
  &.expand {
    transition: all 0.3s ease-in-out;
    max-height: 2000px;
    z-index: 1001;
  }
  &.fullscreen {
    transition: all 0.3s ease-in-out;
    width: calc(100vw - 50px);
    max-height: 2000px;
  }
}
</style>

<style lang="scss">
.vac-col-messages {
  overflow: visible !important;
}
</style>
